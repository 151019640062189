import React from 'react'

import { Header, Footer, Divider } from 'Components'

import './Layout.css'

const Layout = ({ children }) => (
  <div className="Layout flex flex-col mx-auto min-h-screen">
    <Header />
    <Divider />
    <main className="flex-1">{children}</main>
    <Divider />
    <Footer />
  </div>
)

export default Layout
