import React, { useState } from 'react'
import { Link } from 'gatsby'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { AiOutlineInstagram, AiFillInstagram } from 'react-icons/ai'
import { Logo } from 'Components'

import './Footer.css'

dayjs.extend(localizedFormat)

const Footer = () => {
  const [isHover, seIsHover] = useState(false)

  return (
    <footer className="Footer flex flex-row items-center justify-evenly w-full">
      <Link to="/">
        <Logo />
      </Link>
      <div className="flex flex-col items-center">
        <div className="text-sm dark:text-gray-300 text-gray-600">Copyright © {dayjs().year()}</div>
        <div className="text-sm dark:text-gray-300 text-gray-600">Gitai Architects</div>
      </div>
      <a
        href="https://instagram.com/gitaiarchitects"
        target="_blank"
        rel="noreferrer"
        className="dark:text-gray-300 text-gray-600"
        onMouseEnter={() => seIsHover(true)}
        onMouseLeave={() => seIsHover(false)}
        style={{ width: 25, height: 25 }}>
        {isHover ? (
          <AiFillInstagram style={{ width: '100%', height: '100%' }} />
        ) : (
          <AiOutlineInstagram style={{ width: '100%', height: '100%' }} />
        )}
      </a>
    </footer>
  )
}

export default Footer
