import React from 'react'

import './Logo.css'

const Logo = () => (
  <div className="w-10 sm:w-12 md:w-14 Logo">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257 391">
      <path
        className="Logo__svg"
        d="M9.5,9.5h237v371H9.5Zm0,91h118m0,94.5V146M250,195H5m242,85.5H128v58"
        transform="translate(0.5 0.5)"
      />
    </svg>
  </div>
)

export default Logo
