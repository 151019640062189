import React from 'react'
import { ImageZoom } from 'Components'

import './ImageBlock.css'

const ImageBlock = ({ block }) => (
  <section className="flex justify-center items-center flex-wrap ImageBlock my-10 lg:my-20">
    {block.map((image, index) => {
      const { fluid, title, description } = image
      return (
        <figure key={`${title}-${index}`} className="m-2 w-3/4 lg:w-96 ImageBlock__figure">
          <ImageZoom fluid={fluid} title={title} />
          <figcaption className="pt-2 text-center text-xs dark:text-gray-300 text-gray-600">{description}</figcaption>
        </figure>
      )
    })}
  </section>
)

export default ImageBlock
