import React from 'react'
import { ImageZoom } from 'Components'

import './MainImageBlock.css'

const MainImageBlock = ({ title, description, fluid }) => (
  <figure className="text-center mx-auto my-10 w-3/4 lg:w-2/4 lg:my-20 MainImageBlock">
    <ImageZoom fluid={fluid} title={title} />
    {description && (
      <figcaption className="pt-2 text-center text-xs dark:text-gray-300 text-gray-600 MainImageBlock__description">
        {description}
      </figcaption>
    )}
  </figure>
)

export default MainImageBlock
