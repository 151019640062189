import React from 'react'
import Img from 'gatsby-image'
import Zoom from 'react-medium-image-zoom'

import './ImageZoom.css'

const wrapperStyle = {
  width: '100%',
  height: '100%',
}

const ImageZoom = ({ fluid, title }) => (
  <Zoom
    className="ImageZoom"
    overlayBgColorEnd="rgba(255, 255, 255, 1)"
    overlayBgColorStart="rgba(255, 255, 255, 0)"
    transitionDuration={300}
    zoomMargin={10}>
    <Img style={wrapperStyle} fluid={fluid} title={title} />
  </Zoom>
)

export default ImageZoom
