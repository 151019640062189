import React from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import { useConfigureLeaflet } from 'Hooks'

import './LocationMapBlock.css'

const LocationMapBlock = ({ location }) => {
  if (typeof window !== 'undefined') {
    useConfigureLeaflet()
    return (
      <section className="w-full shadow-inner subpixel-antialiased LocationMapBlock">
        <MapContainer center={location} scrollWheelZoom={false} zoom={16} style={{ height: 350 }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={location} shadowPane="shadowPane" />
        </MapContainer>
      </section>
    )
  }
  return null
}

export default LocationMapBlock
