import React from 'react'

import './DescriptionBlock.css'

const DescriptionBlock = ({ description }) => (
  <section className="dark:text-gray-300 text-gray-600 my-10 lg:my-20 w-3/4 lg:w-2/4 mx-auto subpixel-antialiased DescriptionBlock">
    <p className="text-justify">{description}</p>
  </section>
)

export default DescriptionBlock
