import React from 'react'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

import './MenuToggle.css'

const MenuToggle = ({ onToggle, isMenuVisible }) => (
  <button type="button" className="md:hidden w-5 h-5 MenuToggle" onClick={onToggle}>
    <div className="dark:text-white text-black">{isMenuVisible ? <AiOutlineClose /> : <AiOutlineMenu />}</div>
  </button>
)

export default MenuToggle
