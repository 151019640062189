import React from 'react'
import { Helmet } from 'react-helmet'

import { siteConfig } from 'Config'
import { useSiteMetadata } from 'Hooks'

const SEO = ({ pathname = '', title, description, image, children }) => {
  const metadata = useSiteMetadata()

  const href = `${metadata.siteUrl}${pathname}`
  return (
    <Helmet titleTemplate={`%s | ${metadata.title}`}>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{title}</title>

      {/* Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description || metadata.description} />

      {/* Open Graph */}
      <meta property="og:url" content={href} />
      <meta property="og:image" content={image || metadata.image} />
      <meta property="og:title" content={title || metadata.title} key="ogtitle" />
      <meta property="og:site_name" content={title || metadata.title} key="ogsitename" />
      <meta property="og:description" content={description || metadata.description} key="ogdesc" />
      {children}
    </Helmet>
  )
}

export default SEO
