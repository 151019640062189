import React from 'react'

import './SpecsBlock.css'

const camel2title = camelCase =>
  camelCase.replace(/([A-Z])/g, match => ` ${match}`).replace(/^./, match => match.toUpperCase())

const SpecsBlock = ({ specs }) => {
  const filteredSpecs = Object.entries(specs).filter(([key, value]) => {
    if (key === 'location') return false
    if (!value) return false
    return true
  })

  return (
    <section className="flex flex-wrap flex-col my-10 lg:my-20 w-3/4 lg:w-2/4 mx-auto subpixel-antialiased SpecsBlock">
      {filteredSpecs.map(([key, value], index) => (
        <div key={`key-${index}`} className="w-full mb-2 flex justify-evenly">
          <div className="flex justify-end items-center w-1/2 text-xs md:text-base mr-4 dark:text-gray-400 text-gray-700">
            {camel2title(key)}
          </div>
          <div className="flex items-center w-1/2 text-xs md:text-base dark:text-gray-100 text-gray-400">
            {key === 'surface' ? (
              <div>
                {value.toLocaleString()}
                <span>m</span>
                <sup>2</sup>
              </div>
            ) : (
              value
            )}
          </div>
        </div>
      ))}
    </section>
  )
}

export default SpecsBlock
