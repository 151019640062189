import React from 'react'

import './ProjectTitleBlock.css'

const ProjectTitleBlock = ({ name, programType, publishDate }) => (
  <div className="text-center mx-auto w-3/4 my-10 lg:my-20 lg:w-2/4 ProjectTitleBlock">
    <div className="text-xl dark:text-gray-600 text-gray-400 subpixel-antialiased">
      {programType || publishDate || '---'}
    </div>
    <h1 className="text-3xl subpixel-antialiased dark:text-gray-300 text-gray-600">{name}</h1>
  </div>
)

export default ProjectTitleBlock
