import React, { useState } from 'react'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

import { Logo, MenuToggle, ThemeToggle } from 'Components'

import './Header.css'

const Header = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const onMenuToggle = () => {
    if (isMenuVisible) setIsMenuVisible(false)
    else setIsMenuVisible(true)
  }

  const onUnpin = () => setIsMenuVisible(false)

  return (
    <Headroom disableInlineStyles upTolerance={5} downTolerance={1} onUnpin={onUnpin}>
      <header className="flex justify-between items-center relative md:justify-evenly h-20 sm:h-24 md:h-32 py-2 md:py-5 w-full Header">
        <MenuToggle onToggle={onMenuToggle} isMenuVisible={isMenuVisible} />

        <Link to="/">
          <Logo />
        </Link>

        {/* <ul className={`${isMenuVisible ? 'flex' : 'hidden'} top-20 sm:top-24`}> */}
        <ul
          className={`${
            isMenuVisible ? 'flex' : 'hidden'
          } absolute transition text-base xl:text-xl subpixel-antialiased left-0 flex-wrap flex-col justify-center items-start shadow-md p-4 top-20 w-full sm:top-24 md:flex md:static md:flex-row md:flex-grow md:justify-evenly md:items-center md:px-4 md:py-0 md:shadow-none md:max-w-2xl xl:max-w-4xl`}>
          <li className="w-full my-1 md:w-auto">
            <Link className="w-full Header__Navlink " activeClassName="active" to="/">
              Home
            </Link>
          </li>
          <li className="w-full my-1 md:w-auto">
            <Link className="w-full Header__Navlink" activeClassName="active" partiallyActive to="/projects">
              Projects
            </Link>
          </li>
          <li className="w-full my-1 md:w-auto">
            <Link className="w-full Header__Navlink" activeClassName="active" partiallyActive to="/education">
              Education
            </Link>
          </li>
          <li className="w-full my-1 md:w-auto">
            <Link className="w-full Header__Navlink" activeClassName="active" to="/press">
              Press
            </Link>
          </li>
          <li className="w-full my-1 md:w-auto">
            <Link className="w-full Header__Navlink" activeClassName="active" to="/about">
              About
            </Link>
          </li>
        </ul>

        <ThemeToggler>
          {({ theme, toggleTheme }) => <ThemeToggle toggleTheme={toggleTheme} theme={theme} />}
        </ThemeToggler>
      </header>
    </Headroom>
  )
}

export default Header
